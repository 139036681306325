import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "break-word-wrap mb-6 text-neutral-dark" }
const _hoisted_2 = { class: "sm:mx-auto sm:w-full xl:w-full 2xl:w-full" }
const _hoisted_3 = { class: "text-neutral-black text-justify break-word-wrap" }
const _hoisted_4 = { class: "p-4" }
const _hoisted_5 = { class: "inline-flex items-center" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "flex justify-center items-center py-4 gap-2" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString($setup.StatementContinue), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "h-96 max-h-[70vh] overflow-y-auto border border-gray-300 rounded-md px-4 py-4",
        onScroll: $setup.handleScroll,
        ref: "contentArea",
        style: {"white-space":"pre-wrap"}
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString($setup.TermsConditions), 1)
      ], 544),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isAgreed) = $event)),
            disabled: !$setup.canCheck,
            class: "form-checkbox border border-green-600 text-green-500 rounded focus:ring-green-500 disabled:border-black-700 custom-checkbox"
          }, null, 8, _hoisted_6), [
            [_vModelCheckbox, $setup.isAgreed]
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "ml-2 text-neutral-grey" }, [
            _createTextVNode("I have read and agree to the Platform Service Agreement"),
            _createElementVNode("span", { class: "text-red-primary" }, "*")
          ], -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn--green-primary px-10 py-2 disabled:bg-status-draft disabled:text-white",
        onClick: $setup.submit,
        disabled: !$setup.isAgreed
      }, " Agree ", 8, _hoisted_8)
    ])
  ], 64))
}