import { defineComponent as _defineComponent } from 'vue'
import TenantRegistrationForm from "@ems/containers/Tenant/TheForm/TenantRegistration.Form.vue";
import { computed, onMounted, ref } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import { ICreateTenantResponse, ITenantRegistration } from "@/models/Tenant";
import TenantTermCondition from "./TenantTermCondition.vue";
import TheClock from "@/components/TheClock.vue";
import TheStep from "@/components/TheStep/TheStep.vue";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { useRouter } from "vue-router";
import {
  ACCOUNT_REGISTRATION_PROGRESS,
  accountRegistrationProgress,
} from "@ems/constants/steps";
import { setTenantIsActive } from "@/utils/storage";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantRegistration',
  setup(__props, { expose: __expose }) {
  __expose();

const isFormValid = ref(false);
const isDialogVisible = ref(true);
const dataSubmit = ref<ITenantRegistration>();
const currentStep = ref<ACCOUNT_REGISTRATION_PROGRESS>(
  ACCOUNT_REGISTRATION_PROGRESS.PRIVACY_POLICY
);
const router = useRouter();

const isLoadingCreateTenant = computed(
  () => TenantUserModule.isLoadingCreateTenantGetter
);

const handleFormValid = (isValid: boolean, data: ITenantRegistration) => {
  isFormValid.value = isValid;
  dataSubmit.value = data;
};

const handleCloseTC = (isAgree: boolean) => {
  isDialogVisible.value = false;
  currentStep.value = ACCOUNT_REGISTRATION_PROGRESS.REGISTRATION;
};

const handleSubmit = async () => {
  isFormValid.value = false;
  try {
    if (dataSubmit.value) {
      const response: ICreateTenantResponse =
        await TenantUserModule.createTenantUser(dataSubmit.value);
      if (response && response.TenantId) {
        setTenantIsActive(true);
        router.push("/tenant/waiting-approval");
      } else {
        isFormValid.value = true;
      }
    } else {
      console.log("Data is empty");
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  isDialogVisible.value = true;
});

const __returned__ = { isFormValid, isDialogVisible, dataSubmit, currentStep, router, isLoadingCreateTenant, handleFormValid, handleCloseTC, handleSubmit, TenantRegistrationForm, computed, onMounted, ref, TheHeader, TheFooter, get ICreateTenantResponse() { return ICreateTenantResponse }, get ITenantRegistration() { return ITenantRegistration }, TenantTermCondition, TheClock, TheStep, get TenantUserModule() { return TenantUserModule }, BaseSpinner, get useRouter() { return useRouter }, get ACCOUNT_REGISTRATION_PROGRESS() { return ACCOUNT_REGISTRATION_PROGRESS }, get accountRegistrationProgress() { return accountRegistrationProgress }, get setTenantIsActive() { return setTenantIsActive } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})