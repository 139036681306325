import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "bg-neutral-light"
}
const _hoisted_2 = { class: "page-container relative" }
const _hoisted_3 = {
  class: "absolute -top-0 right-12 text-gray-400 z-100",
  "data-aos": "fade-left",
  "data-aos-duration": "1000"
}
const _hoisted_4 = { class: "pt-10 flex items-center justify-center" }
const _hoisted_5 = { class: "flex justify-center min-h-screen" }
const _hoisted_6 = { class: "sm:mx-auto w-full max-w-4xl py-4" }
const _hoisted_7 = { class: "py-4 relative overflow-hidden rounded-lg" }
const _hoisted_8 = { class: "flex justify-center items-center py-4 gap-2" }
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return ($setup.isLoadingCreateTenant)
    ? (_openBlock(), _createBlock($setup["BaseSpinner"], {
        key: 0,
        "full-screen": ""
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode($setup["TheHeader"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["TheClock"])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(_component_el_dialog, {
          modelValue: $setup.isDialogVisible,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.isDialogVisible) = $event)),
          width: "40%",
          title: "Platform Service Agreement",
          "show-close": false,
          "close-on-click-modal": false,
          class: "custom-dialog"
        }, {
          title: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("div", { style: {"text-align":"center","font-weight":"bold","font-size":"20px","color":"#7b7b7b"} }, " Platform Service Agreement ", -1)
          ])),
          default: _withCtx(() => [
            _createVNode($setup["TenantTermCondition"], { onIsClose: $setup.handleCloseTC })
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["TheStep"], {
            currentStep: $setup.currentStep,
            steps: $setup.accountRegistrationProgress
          }, null, 8, ["currentStep", "steps"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["TenantRegistrationForm"], {
                isFormValid: $setup.isFormValid,
                onFormValid: $setup.handleFormValid
              }, null, 8, ["isFormValid"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("button", {
                class: "btn btn--green-primary px-10 py-2 disabled:bg-status-draft disabled:text-white text-md rounded-md",
                disabled: !$setup.isFormValid,
                onClick: _withModifiers($setup.handleSubmit, ["prevent"])
              }, " Submit ", 8, _hoisted_9)
            ])
          ])
        ]),
        _createVNode($setup["TheFooter"], { class: "page-footer" })
      ]))
}