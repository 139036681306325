import { defineComponent as _defineComponent } from 'vue'
import { ref } from "vue";
import {
  TermsConditions,
  StatementContinue,
} from "@ems/containers/Auth/TheDialog/contentVerify";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantTermCondition',
  emits: ["is-close"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const canCheck = ref(false);
const isAgreed = ref(false);
const contentArea = ref(null);
const emit = __emit;

const handleScroll = () => {
  const el = contentArea.value;
  if (el) {
    const { scrollTop, scrollHeight, clientHeight } = el;
    if (scrollTop + clientHeight >= scrollHeight - 1) {
      canCheck.value = true;
    }
  }
};

const submit = () => {
  emit("is-close", isAgreed.value);
};

const __returned__ = { canCheck, isAgreed, contentArea, emit, handleScroll, submit, ref, get TermsConditions() { return TermsConditions }, get StatementContinue() { return StatementContinue } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})